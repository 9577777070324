@import "variables.css";

.footer {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 50px;
    padding-top: 10px;
    vertical-align: center;
    font-family: 'Montserrat', sans-serif;
}


#name {
    padding-left: 70px;
    float: left;
    color: black;
    text-decoration: none;
}




#icons {
    padding-right: 70px;
    float: right; 
}

.icon {
    margin-right: 40px;
    color: black;
}

.icon:hover {
    size: 20;
    color: grey;
}

.dark-mode .footer {
    background-color: var(--light-purple);
    color: black;
}

.light-mode .footer {
    background-color: var(--light-yellow);
    color: black;
}

