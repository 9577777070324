/* Left contact page */
.contact-form-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
}

.form-horizontal {
    /*float: left;*/
    max-width: 600px;
    width: 600px;
    font-family: 'Lato';
    font-weight: 400;
  }
  
  .form-control, 
  textarea {
    max-width: 600px;
    margin-bottom: 20px; 
    color: black;
    letter-spacing: 1px;
    font-family: 'Montserrat', sans-serif;
    font-size: 100%;
  }
  
  .send-button {
    margin-top: 0px;
    height: 34px;
    width: 100px;
    font-family: 'Montserrat', sans-serif;
    overflow: hidden;
    transition: all .2s ease-in-out;
  }
  
  
  
  .send-text {
    display: block;
    margin-top: 10px;
    font: 700 12px 'Montserrat', sans-serif;
    letter-spacing: 2px;
  }

  .form-control:focus {
    border-color: grey;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.075) inset;
}
 