@import "variables.css";

.toggle-container {
  display: flex;
}

.toggle-container input[type=checkbox]{
  height: 0;
  width: 0;
  visibility: hidden;
}
  
.toggle-container label {
  cursor: pointer;
  text-indent: -9999px;
  background: var(--orange);
  width: 50px;
  height: 25px;
  display: block;
  border-radius: 100px;
  position: relative;
}

.toggle-container label:after {
  content: '';
  position: absolute;
  top: 3px;
  left: 3px;
  width: 19px;
  height: 19px;
  background: #fff;
  border-radius: 90px;
  transition: 0.3s;
}

.toggle-container input:checked + label {
  background: var(--other-purple);
}

.toggle-container input:checked + label:after {
  left: calc(100% - 3px);
  transform: translateX(-100%);
}

.toggle-container label:active:after {
  width: 27px;
}

#sun {
  margin-right: 10px;
}

#moon {
  margin-left: 10px;
}