:root {
    --light-yellow: #FEECAE;
    --yellow: #FBE7A6;
    --light-orange: #FCB971;
    --orange: #FAB76D;
    --brighter-orange: #FFAD53;
    --deep-purple: #342236;
    --light-blue: #D1FDFF;
    --blue: #70B4C1;
    --dark-blue: #2F3C6C;
    --light-purple: #CFB8D2;
    --darker-blue: #456990;
    --dark-purple: #201335;
    --other-purple: #2E1E49;
    
    position: relative;
}

.title-font {
    font-family: 'Catamaran', sans-serif;
}
