@import "variables.css";

iframe#webpack-dev-server-client-overlay{display:none!important}

html {
    scroll-behavior: smooth;
}

.light-mode body {
    background-color: var(--light-yellow);
}

.dark-mode body {
    background-color: var(--light-purple);
}