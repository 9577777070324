#headshot-pic {
  width: 100%;
  margin-top: -100px;
}

#thanks {
  padding-left: 75px;
}

.contact-page {
  margin-top: 10px;
  height: 100%;
}

.contact-page-container {
  padding-left: 10px;
  padding-right: 10px;
  width: 100%;
  display: flex;
}

.column-one {
  width: 55%;
}

.column-two {
  width: 45%;
}

#form-container {
  padding-bottom: 50px;
  padding-top: 30px;
}

#links-row-one {
  padding-left: 55px;
  padding-bottom: 10px;
}

#links-row-two {
  display: flex;
  justify-content: center;
}

#contact-header {
  font-size: 3.5em;
  padding-bottom: 20px;
  padding-left: 70px;
}
.light-mode #contact-header {
  color: black;
}
.dark-mode #contact-header {
  color: black;
}

.contact-button {
  border: none;
  color: black;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 4px;
}
.light-mode .contact-button {
  background-color: var(--brighter-orange);
}
.dark-mode .contact-button {
  background-color: var(--deep-purple);
  color: white;
}

.contact-button:hover {
  background-color: var(--brighter-orange);
  transform: scale(1.02);
  transition-duration: 0.5s;
}
