@import "variables.css";

.projects-container {
  padding-left: 255px;
  padding-right: 230px;
  padding-bottom: 150px;
  padding-top: 100px;
  text-align: left;
}
.light-mode .projects-container {
  background-color: white;
}
.dark-mode .projects-container {
  background-color: #0f0b0f;
}

#project-header {
  font-size: 3.5em;
  padding-bottom: 70px;
}
.light-mode #project-header {
  color: black;
}
.dark-mode #project-header {
  color: white;
}

.project-name a {
  text-decoration: none;
  color: white;
  transition: all 0.2s;
  font-size: 1em;
  font-weight: bold;
}

.project-name.smaller-title a {
  font-size: 0.85em !important;
}

.project-name a:hover {
  color: var(--brighter-orange);
  transform: scale(1.1);
}

.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.column {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
  text-align: left;
}

.project-div {
  position: relative;
  border-radius: 8px;
  margin-bottom: 25px;
  color: white;
}

.tag-row {
  display: flex;
}

.tag {
  background-color: grey;
  border-radius: 20px;
  padding-left: 10px;
  padding-right: 10px;
  margin-right: 10px;
}

.overlay {
  position: absolute;
  z-index: 10;
  bottom: 0;
  padding-left: 30px;
  padding-right: 20px;
  overflow: hidden;
  width: 100%;
  opacity: 0%;
  transition: 0.5s ease;
}

.tall-project .overlay {
  height: 30%;
}

.short-project .overlay {
  height: 45%;
}

.project-div:after {
  content: "\A";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border-radius: 8px;
  background: linear-gradient(to bottom, transparent 0%, black 120%);
  opacity: 0;
  transition: all 0.5s;
  -webkit-transition: all 0.2s;
}
.project-div:hover:after {
  opacity: 1;
}
.tall-project:hover .overlay {
  height: 35%;
  opacity: 100%;
}
.tall-project.long-text:hover .overlay {
  height: 40%;
  opacity: 100%;
}
.short-project:hover .overlay {
  height: 50%;
  opacity: 100%;
}
.short-project.long-text:hover .overlay {
  height: 62%;
  opacity: 100%;
}

.tall-project {
  position: relative;
  height: 450px;
}

.short-project {
  position: relative;
  height: 280px;
}

.background-video {
  z-index: -1;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
}

.background-img {
  z-index: -1;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
}

.project-6 {
  background-color: blue;
}
