@import "variables.css";

body {
  min-height: 100vh;
  overflow-x: hidden;
  font-family: "Montserrat", sans-serif;
}

.hero {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 650px;
  font-family: "Montserrat", sans-serif;
}

.dark-mode .hero {
  background-color: var(--light-purple);
}
.light-mode .hero {
  background-color: var(--light-yellow);
}

#reactText {
  font-size: 0.8em;
  padding-left: 20px;
  color: black;
}

.hero-text {
  position: absolute;
  font-size: 2.5em;
}

.dark-mode .hero-text {
  color: black;
}

/* SHOULD WE CHANGE COLOR HERE?*/
.light-mode .hero-text {
  color: black;
}

.hero img {
  position: absolute;
  top: -300px;
  left: 0;
  max-width: 100%;
  pointer-events: none;
}

.floating-left {
  animation-name: floating-left;
  animation-duration: 7s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

@keyframes floating-left {
  0% {
    transform: translate(0, 0px);
  }
  50% {
    transform: translate(30px, 0);
  }
  100% {
    transform: translate(-0px, 0);
  }
}

.floating-right {
  animation-name: floating-right;
  animation-duration: 9s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  margin-top: 5px;
}

@keyframes floating-right {
  0% {
    transform: translate(0, 0px);
  }
  50% {
    transform: translate(-30px, 0);
  }
  100% {
    transform: translate(-0px, 0);
  }
}

/* Use a media query to add a break point at 800px: */
@media screen and (max-width: 800px) {
  .hero {
    height: 80vh;
  }
}
