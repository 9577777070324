@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');
@import "variables.css";


.navbar {
  height: 70px;
  top: 0;
  width: 100%;
}

.light-mode .navbar {
  background-color: var(--light-yellow);
}
.dark-mode .navbar {
  background-color: var(--light-purple);
}

.nav-container {
  margin: 0 auto;
  padding: 0 10px;
  padding-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

.nav-elements {
  text-align: center;
  float: none;
  
  
}

.nav-elements ul {
  display: flex;
  justify-content: space-between;
  list-style-type: none;
}

.nav-elements ul li:not(:last-child) {
  margin-right: 40px;
}

.nav-elements ul li:hover {
  transition: all 0.3s ease-in-out;
  transform: scale(1.1);
}

.nav-elements ul a {
  font-size: 15px;
  font-weight: 400;
  text-decoration: none;
}

.dark-mode .nav-elements ul a {
  color: white;
}
.light-mode .nav-elements ul a {
  color: black;
}



.nav-elements ul li.active {
    border-bottom: red 4px solid;
}

.nav-elements ul a.active::after {

}

.toggle {
    position: absolute;
    right: 0px;
    padding-right: 50px;
    margin-top: 0px;
}