@import "variables.css";

.about-section {
  height: 800px;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "Montserrat", sans-serif;
  font-size: 105%;
}

.dark-mode .about-section {
  background-color: var(--deep-purple);
  color: #dedbdf;
}
.light-mode .about-section {
  background-color: white;
  color: black;
}

/* LIGHT MODE change the slider root color */
.light-mode .MuiSlider-root {
  color: var(--brighter-orange) !important;
}
/* DARK MODE change the slider root color */
.dark-mode .MuiSlider-root {
  color: var(--light-purple) !important;
}

/* LIGHT MODE set the slider thumb shadow color (orange), size, and opacity */
.light-mode .MuiSlider-thumb.Mui-focusVisible {
  box-shadow: 0 0 0 8px rgba(255, 165, 0, 0.15) !important;
}
.light-mode .MuiSlider-thumb:focus {
  box-shadow: 0 0 0 8px rgba(255, 165, 0, 0.15) !important;
}
.light-mode .MuiSlider-thumb:hover {
  box-shadow: 0 0 0 8px rgba(255, 165, 0, 0.25) !important;
}
.light-mode .MuiSlider-thumb:active {
  box-shadow: 0 0 0 12px rgba(255, 165, 0, 0.25) !important;
}

/* DARK MODE set the slider thumb shadow color (light purple), size, and opacity */
.dark-mode .MuiSlider-thumb.Mui-focusVisible {
  box-shadow: 0 0 0 8px rgba(207, 184, 210, 0.2) !important;
}
.dark-mode .MuiSlider-thumb:focus {
  box-shadow: 0 0 0 8px rgba(207, 184, 210, 0.2) !important;
}
.dark-mode .MuiSlider-thumb:hover {
  box-shadow: 0 0 0 8px rgba(207, 184, 210, 0.3) !important;
}
.dark-mode .MuiSlider-thumb:active {
  box-shadow: 0 0 0 12px rgba(207, 184, 210, 0.3) !important;
}

/* LIGHT MODE change the color of the slider text labels */
.light-mode .bio-slider .MuiSlider-markLabel {
  color: black !important;
  font-family: "Montserrat", sans-serif;
}
/* DARK MODE change the color of the slider text labels */
.dark-mode .bio-slider .MuiSlider-markLabel {
  color: white !important;
  font-family: "Montserrat", sans-serif;
}

.contact-section {
  display: flex;
  justify-content: space-around;
  width: 90%;
  margin-top: 20px;
}

.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  padding-bottom: 0px;
}

.pic-section {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1.25;
  margin-top: 50px;
}

.bio {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 2.25;
  align-items: center;
  height: 550px;
  padding-top: 50px;
  padding-left: 20px;
  padding-right: 30px;
}

#headshot-picture {
  width: 100%;
}

.about-button {
  border: none;
  color: black;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 4px;
}
.light-mode .about-button {
  background-color: var(--light-yellow);
}
.dark-mode .about-button {
  background-color: var(--light-purple);
}

.about-button:hover {
  background-color: var(--brighter-orange);
  transform: scale(1.02);
  transition-duration: 0.5s;
}

#slider-container {
  padding-bottom: 50px;
}

.slider-title {
  color: grey;
  font-size: 0.75em;
}

#contact-heading {
  margin-top: 30px;
  margin-bottom: 50px;
  font-size: 170%;
  font-weight: 700;
}
