@import "variables.css";

.fixed-bg {
    height: 100%;
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}

.light-mode .fixed-bg {
    background-image: url('../assets/orangeart.jpg');
}

.dark-mode .fixed-bg {
    background-image: url('../assets/purple-flow.jpg');
}

.empty {
    height: 250px;
}